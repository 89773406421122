import React from "react";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import { Container, Grid, Flex, Box, css, useThemeUI } from "theme-ui";
import ContentText from "@solid-ui-components/ContentText";
import ContentContainer from "@solid-ui-components/ContentContainer";
import Reveal from "@solid-ui-components/Reveal";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import getImage from "@solid-ui-components/utils/getImage";
import ContentImages from "@solid-ui-components/ContentImages";
import PlayerModal from "@solid-ui-blocks/Modal/PlayerModal";
import DownloadLink from "@solid-ui-components/DownloadLink";

const styles = (theme) => ({
  wrapper: {
    flex: [`100%`, null, null, 1],
    minWidth: 300,
    maxWidth: [`none`, null, null, 600],
    cursor: 'pointer',
    p: 3,
  },
  card: {
    overflow: 'hidden',
    height: theme.sizes.full,
  },
  content: {
    alignItems: `stretch`,
    flexDirection: 'column',
    height: theme.sizes.full,
  },
  body: {
    display: `flex`,
    flexDirection: `column`,
    justifyContent: `center`,
    flex: 1,
    p: 4,
    // minHeight: '366px'
  },
  mediaWrapper: {
    textAlign: `center`,
    position: `relative`,
    display: `block`,
    height: theme.sizes.full,
  },
  media: {
    // display: [`none`, `block`],
    display: 'block',
    height: theme.sizes.full,
    bg: theme.colors.omegaLighter,
    borderRadius: theme.radii.default,
    // minHeight: `15rem`,
    div: {
      p: `0 !important`,
    },
  },
  avatar: {
    size: 42,
    bg: 'transparent',
    // borderRadius: theme.radii.full,
    // borderStyle: `solid`,
    borderWidth: theme.borderWidths.md,
    borderColor: theme.colors.omegaLighter,
    boxSizing: `content-box`,
    img: {
      objectPosition: "top center !important",
    },
  },
});

const BlogBlock02 = ({
  content: { collection }, titleSX,
  columns = [3],
  gap = 3.9,
}) => {
  const { theme } = useThemeUI();
  return (
    <Container>
      {collection && (
        <Reveal effect="fadeInGrow" duration={1}>
          <Grid sx={{ m: -3, px: 3 }} columns={columns} gap={gap}>
            {collection.map(
              (
                {
                  container,
                  title,
                  description,
                  images,
                  avatar,
                  file,
                  video,
                },
                index
              ) => (
                <Box key={`item-${index}`} sx={styles(theme).wrapper}>
                  <ContentContainer
                    content={container}
                    variant="cards.interactive"
                    sx={styles(theme).card}
                  >
                    <Flex as="article" sx={styles(theme).content}>
                      {/* Image */}
                      <Box sx={{ m: 2, mb: [null, null, null, 0] }}>
                        <Box sx={styles(theme).mediaWrapper}>
                          {video ?
                            (<>
                                <ContentImages content={{ images }} imgStyle={styles(theme).media} />
                                {video?.src?.publicURL &&
                                  <div
                                    style={{
                                      position: "absolute",
                                      top: 'calc(50% - 24px)',
                                      left: 'calc(50% - 32px)'
                                    }}
                                  >
                                    <PlayerModal video={video} />
                                  </div>
                                }
                              </>
                            )
                            :
                            <ContentImages content={{ images }} imgStyle={styles(theme).media} />
                          }
                        </Box>
                      </Box>
                      <Box sx={styles(theme).body}>
                        {/* Title */}
                        <ContentText
                          content={title}
                          variant="h5"
                          sx={{ flex: [0, 0, `auto`], ...titleSX}}
                        />
                        {/* Excerpt */}
                        <ContentText
                          content={description}
                          variant="small"
                          sx={{ flex: `auto`, mb: 4 }}
                        />
                        {/* Footer */}
                        <Flex sx={{ alignItems: 'center' }}>
                          <Img
                            image={getImage(avatar?.src)}
                            alt={avatar?.alt}
                            css={css(styles(theme).avatar)}
                          />
                          {file?.src?.publicURL && <DownloadLink file={file} space={2} />}
                        </Flex>
                      </Box>
                    </Flex>
                  </ContentContainer>
                </Box>
              )
            )}
          </Grid>
        </Reveal>
      )}
    </Container>
  );
};

export default WithDefaultContent(BlogBlock02);
