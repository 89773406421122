import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "@solid-ui-layout/Layout";
import Seo from "@solid-ui-components/Seo";
import Divider from "@solid-ui-components/Divider";
import { normalizeBlockContentNodes } from "@blocks-helpers";
import AppWrapper from '@helpers/appContext';
import Blog from "@solid-ui-blocks/Blog/Block02";

import theme from "./_theme";

const Demos = (props) => {
  const { selectedLanguage } = props;
  const { allBlockContent } = useStaticQuery(query);

  const content = normalizeBlockContentNodes(allBlockContent?.nodes);
  const demos = content[`demos.${selectedLanguage}`]

  return (
    <Layout theme={theme} {...props}>
      <Seo title="Demos" />

      <Divider space="5" />
      <Divider space="5" />
      
      <Blog
        content={{ collection: demos.collection }}
        columns={[1, null, null, 2]}
        gap={0}
      />

      <Divider space="5" />
    </Layout>
  );
};

const query = graphql`
  query siteDemosBlockContent {
    allBlockContent(
      filter: {
        page: {
          in: [
            "site/demos"
            "site/shared/book-demo"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;

const DemosPage = (props) => (
  <AppWrapper>
    <Demos {...props}/>
  </AppWrapper>
);

export default DemosPage;